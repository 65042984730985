import {atom} from "recoil";


export const StoreDataAtom = atom({
  key: 'store-data-atom',
  default: []
})


export const StoreEditDataAtom = atom({
  key: 'store-edit-data-atom',
  default: {}
})


export const MenuDataAtom = atom({
  key: 'menu-data-atom',
  default: []
})

export const MenuEditDataAtom = atom({
  key: 'menu-edit-data-atom',
  default: {}
})
import React from "react";
import {Menu, Button} from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ReadOutlined,
  ShopOutlined,
} from '@ant-design/icons';

export const SideNav = ({setSelectedNav}) => {
  const [collapsed, setCollapsed] = React.useState(false)

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  const MenuData = (item, key) => {
    setSelectedNav(key)
  }

  return (
    <div style={{width: '15%', height: `100vh`}}>
      <Menu
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
        theme="light"
        inlineCollapsed={collapsed}
        style={{height: '100%'}}
        onClick={({item, key}) => MenuData(item, key)}
      >
        <Button type="text" onClick={toggleCollapsed}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
        </Button>

        <Menu.Item key="1" icon={<ShopOutlined/>}>
          Store
        </Menu.Item>

        <Menu.Item key="2" icon={<ReadOutlined />}>
          Menu
        </Menu.Item>

        {/*<Menu.Item key="3" icon={<ContainerOutlined/>}>*/}
        {/*  News*/}
        {/*</Menu.Item>*/}

        {/*<Menu.Item key="4" icon={<AuditOutlined/>}>*/}
        {/*  IR*/}
        {/*</Menu.Item>*/}

      </Menu>
    </div>
  )
}

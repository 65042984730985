import {Routes, Route} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route exact={true} path={'/'} element={<Login/>}/>
        <Route exact={true} path={'/dashboard'} element={<Dashboard/>}/>
      </Routes>
    </div>
  );
}

export default App;

import React from "react";
import {SideNav} from "../../blocks/Layouts/SideNav";
import styled from "styled-components";
import {ShareHeader} from "../../blocks/Layouts/ShareHeader";
import Store from "../Store";
import Menu from "../Menu";
import {useMediaQuery} from "react-responsive";
import nonono from '../../../assets/images/nonono.gif'

export const Dashboard = () => {
  const [selectedNav, setSelectedNav] = React.useState("1")
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});

  return (
    <Component className={'dashboardPage'}>

      {isTabletOrMobile &&
        <RejectMobile>
          <RejectImageSection>
            <RejectImage src={nonono} />
          </RejectImageSection>
          <RejectText>Please Access from computer or fullscreen</RejectText>
        </RejectMobile>
      }

      <SideNav setSelectedNav={setSelectedNav}/>

      <DashboardBody>
        <ShareHeader selectedNav={selectedNav}/>
        {selectedNav === "1" && <Store/>}
        {selectedNav === "2" && <Menu/>}
        {/*{selectedNav === "3" && <News/>}*/}
        {/*{selectedNav === "4" && <InvestorRelations/>}*/}
      </DashboardBody>

    </Component>
  )
}

const Component = styled.div`
  display: flex;
  overflow: hidden;
`

const DashboardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 85%;
`

const RejectMobile = styled.div`
  background-color: black;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const RejectImageSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const RejectImage = styled.img`
  width: 100%;
  margin-bottom: 50px;
  //animation: smile infinite 0.5s ;
  //
  //@keyframes smile {
  //  0% {
  //    transform: matrix(1, 0, 0, 1, 0, 30);
  //  }
  //
  //  100% {
  //    transform: matrix(1, 0, 0, 1, 0, 0);
  //  }
  //}
`

const RejectText = styled.div`
  color: #FFBE00;
  font-size: 4vw;
  text-align: center;
  font-weight: bold;
`
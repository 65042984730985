import React from "react";
import { Form, Input, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import {login} from "../../services/api"
import {ErrorNoti} from "../../hooks/ShareNotification";

export const LoginForm = () => {
  let navigate = useNavigate();

  const onFinish = (values) => {
    const {userName, password} = values;

    login(userName, password).then(
      res => {
        if(res?.msg === 'ok'){
          localStorage.setItem("user-token", res.data.token)
          navigate('/dashboard')
        }
      }
    ).catch(error => {
      ErrorNoti("문제가 발생하여 로그인을 할 수 없습니다.")
      console.log("LOGIN ERROR =>>>", error)
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('FAILED LOGIN =>>>', errorInfo);
  };

  return(
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="ID"
        name="userName"
        rules={[{ required: true, message: 'Please input your ID' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" style={{width: 100}}>
          Login
        </Button>
      </Form.Item>
    </Form>
  )
}

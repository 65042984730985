import React from "react";
import {Button, PageHeader} from 'antd';
import { useNavigate } from "react-router-dom";
// import {api} from "../../../services";

export const ShareHeader = ({selectedNav}) => {
  const navigation = useNavigate();

  return(
    <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', padding: '1% 0'}}>
      <PageHeader
        className="site-page-header"
        title={`${
          // eslint-disable-next-line no-mixed-operators
          selectedNav === "1" && 'Store' ||
          // eslint-disable-next-line no-mixed-operators
          selectedNav === "2" && 'Menu'
          // // eslint-disable-next-line no-mixed-operators
          // selectedNav === "3" && 'News' ||
          // // eslint-disable-next-line no-mixed-operators
          // selectedNav === "4" && 'IR' 
        }`}
        // subTitle="This is a subtitle"
        extra={
          <Button type={"danger"} style={{borderRadius: 6}} onClick={() => {
            // api.logout().catch()
            navigation('/')
          }}>LogOut</Button>
        }
      />
    </div>
  )
}
import axios from 'axios'
import {ErrorNoti} from "../hooks/ShareNotification";

const API_URL = "https://api.gongdorikitchen-admin.com"


const getToken = () => {
  return localStorage.getItem("user-token");
}

export const login = async (userName, password) => {
  const data = {
    userName: userName,
    password: password
  }

  const res = await axios.post(`${API_URL}/admin/user/signin`, data)

  return res.data
}

// ***** STORE START *****

export const setStore = async (data) => {

  const res = await  axios.post(
    `${API_URL}/admin/store`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}` ?? ""
      },
    }).catch(error => {
    ErrorNoti(error.response.data.data.errMsg)

    if(error.response.data.errCode === 401) {
      return 401
    }
  })

  return res.data
}

export const getStore = async () => {

  const res = await  axios.get(
    `${API_URL}/admin/store`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}` ?? ""
      },
    }).catch(error => {
    ErrorNoti(error.response.data.data.errMsg)

    if(error.response.data.errCode === 401) {
      return 401
    }
  })

  return res.data
}

export const editStore = async (data) => {
  const res = await  axios.patch(
    `${API_URL}/admin/store`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}` ?? ""
      },
    })

  return res.data
}

export const deleteStore = async (data) => {
  const res = await  axios.delete(
    `${API_URL}/admin/store`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}` ?? ""
      },
      data:  data
    }).catch(error => {
    ErrorNoti(error.response.data.data.errMsg)

    if(error.response.data.errCode === 401) {
      return 401
    }
  })

  return res.data
}

// ***** STORE END *****




// ***** MENU START *****

export const setMenu = async (data) => {

  const res = await  axios.post(
    `${API_URL}/admin/menu`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}` ?? ""
      },
    }).catch(error => {
    ErrorNoti(error.response.data.data.errMsg)

    if(error.response.data.errCode === 401) {
      return 401
    }
  })

  return res.data
}

export const getMenu = async () => {

  const res = await  axios.get(
    `${API_URL}/admin/menu`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}` ?? ""
      },
    }).catch(error => {
    ErrorNoti(error.response.data.data.errMsg)

    if(error.response.data.errCode === 401) {
      return 401
    }
  })

  return res.data
}

export const editMenu = async (data) => {

  const res = await  axios.patch(
    `${API_URL}/admin/menu`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}` ?? ""
      },
    }).catch(error => {
    ErrorNoti(error.response.data.data.errMsg)

    if(error.response.data.errCode === 401) {
      return 401
    }
  })

  return res.data

}

export const deleteMenu = async (data) => {
  const res = await  axios.delete(
    `${API_URL}/admin/menu`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}` ?? ""
      },
      data:  data
    }).catch(error => {
    ErrorNoti(error.response.data.data.errMsg)

    if(error.response.data.errCode === 401) {
      return 401
    }
  })

  return res.data
}


// ***** MENU END *****

export const setImage = async (data) => {

    const res = await axios.post(
      `${API_URL}/admin/menuImg`,
      data,
      {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${getToken()}` ?? ""
      },
    }).catch (error => {
      ErrorNoti(error.response.data.data.errMsg)

      if(error.response.data.errCode === 401) {
        return 401
      }
    })

    return res.data
}


export const getStoreAddress = async (data) => {

  const res = await axios.post(
    `${API_URL}/admin/address`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}` ?? ""
      },
    }).catch (error => {
    ErrorNoti(error.response.data.data.errMsg)

    if(error.response.data.errCode === 401) {
      return 401
    }
  })

  return res.data
}
import React from "react";
import {Button, Drawer, Form, Input, Select} from 'antd';
import styled from "styled-components";
import {ImageUpload} from "../../atoms/ImageUpload";
import {editMenu, setMenu} from "../../../services/api";
import {useRecoilValue} from "recoil";
// import moment from "moment";
import {SuccessNoti} from "../../../hooks/ShareNotification";
import {useNavigate} from "react-router-dom";
import {MenuEditDataAtom} from "../../../recoil";

export const MenuDrawer = ({menuDrawerVisible, setMenuDrawerVisible, drawerType, onMenuQuery}) => {
  const [imageUrl, setImageUrl] = React.useState();
  const [menuId, setMenuId] = React.useState();
  const MenuEditDataValue = useRecoilValue(MenuEditDataAtom);
  const [form] = Form.useForm();
  let navigate = useNavigate();

  // EDIT DATA START
  React.useEffect(() => {
    if(drawerType === 'EDIT' && MenuEditDataValue){

      const {menu_id, type, menu_name, description, menu_image} = MenuEditDataValue;

      setImageUrl(menu_image);
      setMenuId(menu_id);

      form.setFieldsValue({
        type: type,
        menuName: menu_name,
        description: description
      })
    } else {
      form.resetFields();
      setImageUrl();
    }
  }, [menuDrawerVisible])
  // EDIT DATA END



  const onClose = () => {
    form.resetFields();
    setImageUrl();
    setMenuDrawerVisible(false)
  }

  const onFinish = (values) => {

    // 데이터셋에 이미지 붙이기
    if(imageUrl) {
      values["menuImage"] = imageUrl
    }

    // Menu Order ID 숫자 전환
    if(values.menuOrderId) {
      values.menuOrderId = Number(values.menuOrderId)
    }

    if(drawerType === 'EDIT') {
      if(menuId > 0){
        values["menuId"] = menuId;
      }
      editMenu(values).then(res => {
        if(res?.msg === 'ok'){
          SuccessNoti('메뉴를 정상적으로 수정하였습니다.')
          onMenuQuery();
        } else if (res === 401) {
          navigate('/');
        }
      })
    } else {
      setMenu(values).then(res => {
        if(res?.msg === 'ok'){
          SuccessNoti('메뉴를 정상적으로 저장하였습니다.')
          onMenuQuery();
        } else if (res === 401) {
          navigate('/');
        }
      })
    }

    onClose();
  };

  return(
    <Drawer
      title={drawerType === "ADD" ? "Add Menu" : "Edit Menu"}
      placement={'right'}
      closable={false}
      onClose={onClose}
      visible={menuDrawerVisible}
      key={"MemberDrawerKey"}
      width={"35%"}
      bodyStyle={{padding: '5%'}}
    >
      <Form onFinish={onFinish} form={form}>

        <Form.Item name={"menuOrderId"} label="Menu Order ID"  rules={[{required: true, message: ''}]} labelCol={{span: 24}}>
          <Input placeholder={"Only Number"}/>
        </Form.Item>

        <Form.Item name={"type"} label="Type" rules={[{required: true, message: ''}]} labelCol={{span: 24}}>
          <Select>
            <Select.Option value={"set"}>
              set (공돌이 세트)
            </Select.Option>

            <Select.Option value={"casual"}>
              casual (분식류)
            </Select.Option>

            <Select.Option value={"rice"}>
              rice (밥류)
            </Select.Option>

            <Select.Option value={"drink"}>
              drink (음료)
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name={"menuName"} label="Menu Name" rules={[{required: true, message: ''}]} labelCol={{span: 24}}>
          <Input/>
        </Form.Item>

        <Form.Item name={"description"} label="Description" rules={[{required: true, message: ''}]} labelCol={{span: 24}}>
          <Input.TextArea />
        </Form.Item>

        <ImageUpload setImageUrl={setImageUrl} imageUrl={imageUrl} imageType={'ir'} />

        <ButtonWrapper>
          <Button type={"primary"} htmlType="submit">
            Add
          </Button>
        </ButtonWrapper>

      </Form>

    </Drawer>
  )
}

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 5%;
`
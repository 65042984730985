import React from "react";
import {Upload} from 'antd';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {setImage} from "../../services/api";
import {useNavigate} from "react-router-dom";


export const ImageUpload = ({imageUrl, setImageUrl}) => {
  const [loading, setLoading] = React.useState(false);
  let navigate = useNavigate();

  const uploadImage = async options => {
    const {file} = options;
    const formData = new FormData();

    formData.append("img", file);
    setLoading(true);

    setImage(formData).then(res => {
      if(res.msg === 'ok'){
        setImageUrl(`${res.data.picUrl}`)
        setLoading(false);
      } else if(res === 401) {
        navigate('/')
      }
    })

  };


  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: 8}}>Image Upload</div>
    </div>
  );

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      customRequest={uploadImage}
      beforeUpload={beforeUpload}
      // onChange={handleChange}
    >
      {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
    </Upload>
  )
}

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener('load', () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    // ErrorNotiFunc({errMsg: 'You can only upload JPG/PNG file!'});
  }
  // const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //   message.error('Image must smaller than 2MB!');
  // }
  // return isJpgOrPng && isLt2M;
}

// const handleChange = (info) => {
//   if (info.file.status === 'uploading') {
//     setLoading(true)
//   }
//
//   if (info.file.status === 'done') {
//
//   }
//
// }
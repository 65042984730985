import React from "react";
import {Button, Space, Table, Popconfirm, Input} from 'antd';
import {DeleteOutlined, EditOutlined, SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {StoreDataAtom, StoreEditDataAtom} from "../../../recoil";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {ErrorNoti, SuccessNoti} from "../../../hooks/ShareNotification";
import {deleteStore} from "../../../services/api";
import {useNavigate} from "react-router-dom";

export const StoreTable = ({setStoreDrawerVisible, setDrawerType, onStoreQuery}) => {
  const setStoreEditDataAtom = useSetRecoilState(StoreEditDataAtom);
  const storeData = useRecoilValue(StoreDataAtom);
  const [searchText, setSearchText] = React.useState("")
  const [searchedColumn, setSearchedColumn] = React.useState("")
  let navigate = useNavigate();
  let searchInput;

  const onDelete = (data) => {
    deleteStore({storeId: data}).then(res => {
      if(res.msg === 'ok'){
        SuccessNoti('가맹점을 정상적으로 삭제하였습니다.')
        onStoreQuery();
      } else if (res === 401) {
        navigate('/');
      }
    })
  }


  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          {/*<Button*/}
          {/*  type="link"*/}
          {/*  size="small"*/}
          {/*  onClick={() => {*/}
          {/*    confirm({ closeDropdown: false });*/}
          {/*    setSearchText(selectedKeys[0]);*/}
          {/*    setSearchedColumn(dataIndex);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Filter*/}
          {/*</Button>*/}
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };


  const MemberColumns = [
    {
      title: 'Store Name',
      dataIndex: 'store_name',
      key: 'store_name_key',
      ...getColumnSearchProps('store_name'),
      sorter: {
        compare: (a, b) => {
          a = a['store_name'].toLowerCase();
          b = b['store_name'].toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address_key',
      ellipsis: true,
      sorter: {
        compare: (a, b) => {
          a = a['address'].toLowerCase();
          b = b['address'].toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone_key',
      sorter: {
        compare: (a, b) => {
          a = a['phone'].toLowerCase();
          b = b['phone'].toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Working Hour',
      dataIndex: 'working_hour',
      key: 'working_hour_key',
      render: (item) => {
        return (
          <div>
            {item.allTime &&
              <div>
                {item.allTime === "holiday" ? `Mon - Sun : ${item.allTime}` : ` Mon - Sun : ${item.allTime.open} - ${item.allTime.close}`}
              </div>
            }

            {
              item.weekday &&
              <>
                <div>
                  {item.weekday === "holiday" ? `Weekday : ${item.weekday}` : `Weekday : ${item.weekday.open} - ${item.weekday.close}`}
                </div>
                <div>
                  {item.weekend === "holiday" ? `Weekend : ${item.weekend}` : `Weekend : ${item.weekend.open} - ${item.weekend.close}`}
                </div>
              </>
            }

            {
              item.mon &&
              <>
                <div>
                  {item.mon === "holiday"  ? `Mon : ${item.mon}` : `Mon :  ${item.mon.open} - ${item.mon.close}`}
                </div>
                <div>
                  {item.tues === "holiday"  ? `Tues : ${item.tues}` : `Tues :  ${item.tues.open} - ${item.tues.close}`}
                </div>
                <div>
                  {item.wed === "holiday"  ? `Wed : ${item.wed}` : `Wed :  ${item.wed.open} - ${item.wed.close}`}
                </div>
                <div>
                  {item.thur === "holiday"  ? `Thur : ${item.thur}` : `Thur :  ${item.thur.open} - ${item.thur.close}`}
                </div>
                <div>
                  {item.fri === "holiday"  ? `Fri : ${item.fri}` : `Fri :  ${item.fri.open} - ${item.fri.close}`}
                </div>
                <div>
                  {item.sat === "holiday"  ? `Sat : ${item.sat}` : `Sat :  ${item.sat.open} - ${item.sat.close}`}
                </div>
                <div>
                  {item.sun === "holiday"  ? `Sun : ${item.sun}` : `Sun :  ${item.sun.open} - ${item.sun.close}`}
                </div>

              </>
            }
          </div>
        )
      }
    },
    {
      title: 'Break Time',
      dataIndex: 'breakTime',
      key: 'breakTime_key',
      render: (item) => {
        return (
          <div>
            <div>{`weekday: ${item.weekday.start} - ${item.weekday.end}`}</div>
            <div>{typeof item.weekend === "string" ? `Weekend : ${item.weekend}` : `Weekend : ${item.weekend.start} - ${item.weekend.end}`}</div>
          </div>
        )
      }
    },
    {
      title: '',
      key: 'member_MemberAction',
      render: (_, elm) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined/>}
            style={{borderRadius: 6}}
            onClick={() => {
              setStoreEditDataAtom(elm);
              setDrawerType("EDIT");
              setStoreDrawerVisible(true);
            }
            }/>
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => onDelete(elm.store_id)}
            icon={<DeleteOutlined style={{color: "red"}} />}
          >
            <Button type="danger" icon={<DeleteOutlined/>} style={{borderRadius: 6}}/>
          </Popconfirm>
        </Space>
      ),
    },
  ];




  return (
    <Table columns={MemberColumns} dataSource={storeData} pagination={{pageSize: 30}}/>
  )
}
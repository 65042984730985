import React from "react";
import styled from "styled-components";
import {Button} from 'antd';
import {StoreTable} from "../../blocks/DashboardBlocks/StoreTable";
import {StoreDrawer} from "../../blocks/SideDrawers/StoreDrawer";
import {getStore} from "../../../services/api";
import {StoreDataAtom} from "../../../recoil";
import {useRecoilState, useSetRecoilState} from "recoil";
import {ErrorNoti, SuccessNoti} from "../../../hooks/ShareNotification";
import {useNavigate} from "react-router-dom";

export const Store = () => {
  const [storeDrawerVisible, setStoreDrawerVisible] = React.useState(false);
  const [drawerType, setDrawerType] = React.useState('')
  const [storeData, setStoreData] = useRecoilState(StoreDataAtom);
  let navigate = useNavigate();

  const onStoreQuery = () => {
    getStore().then(res => {
      if(res.msg === 'ok'){
        setStoreData(res?.data.reverse());
      } else if (res === 401) {
        navigate('/');
      }
    })
  }

  React.useEffect(() => {
    onStoreQuery();
  }, [])

  return (
    <Component>

      <ButtonSection>
        <TotalQty>
          {/*{`Total: ${memberData.length}`}*/}
        </TotalQty>

        <Button
          type="primary"
          style={{borderRadius: 6}}
          onClick={() => {
            setDrawerType('ADD');
            setStoreDrawerVisible(true);
          }}>
          Add Store
        </Button>
      </ButtonSection>

      <StoreTable setStoreDrawerVisible={setStoreDrawerVisible} setDrawerType={setDrawerType} onStoreQuery={onStoreQuery}/>

      <StoreDrawer storeDrawerVisible={storeDrawerVisible} setStoreDrawerVisible={setStoreDrawerVisible} drawerType={drawerType} onStoreQuery={onStoreQuery}/>

    </Component>
  )
}

const Component = styled.div`
  padding: 2%;
`

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1%;
`

const TotalQty = styled.div`
  font-size: 18px;
  font-weight: 600;
`


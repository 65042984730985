import React from "react";
import styled from "styled-components";
import {LoginForm} from "../../blocks/LoginForm";
import {useMediaQuery} from "react-responsive";
import gongdoriLogo from "../../../assets/images/gongdoriLogo.webp"
import nonono from "../../../assets/images/nonono.gif"


export const Login = () => {
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1024px)'});

  return (
    <Componenet>

      {isTabletOrMobile &&
        <RejectMobile>
          <RejectImageSection>
            <RejectImage src={nonono} />
          </RejectImageSection>
          <RejectText>Please Access from computer or fullscreen</RejectText>
        </RejectMobile>
      }

      <LogoImage src={gongdoriLogo} width={350}/>

      <LoginForm />

    </Componenet>
  )
}


const Componenet = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: 100vh;
`

const LogoImage = styled.img`
  margin-bottom: 50px;
`

const RejectMobile = styled.div`
  background-color: black;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const RejectImageSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const RejectImage = styled.img`
  width: 100%;
  margin-bottom: 50px;
  //animation: smile infinite 0.5s ;
  //
  //@keyframes smile {
  //  0% {
  //    transform: matrix(1, 0, 0, 1, 0, 30);
  //  }
  //
  //  100% {
  //    transform: matrix(1, 0, 0, 1, 0, 0);
  //  }
  //}
`

const RejectText = styled.div`
  color: #FFBE00;
  font-size: 4vw;
  text-align: center;
  font-weight: bold;
`
//
// const ContentsSection = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   background-color: #f1efef;
//   border-radius: 15px;
//   width: 30%;
//   padding: 5% 0;
// `

import React from "react";
import {Button, Space, Table, Popconfirm, Input} from 'antd';
import {DeleteOutlined, EditOutlined, SearchOutlined} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {useSetRecoilState} from "recoil";
import {SuccessNoti} from "../../../hooks/ShareNotification";
import {MenuEditDataAtom} from "../../../recoil";
import {deleteMenu} from "../../../services/api";
import {useNavigate} from "react-router-dom";

export const MenuTable = ({setMenuDrawerVisible, setDrawerType, menuData, onMenuQuery}) => {
  const setMenuEditDataAtom = useSetRecoilState(MenuEditDataAtom);
  const [searchText, setSearchText] = React.useState("")
  const [searchedColumn, setSearchedColumn] = React.useState("")
  let navigate = useNavigate();
  let searchInput;

  const onDelete = (data) => {
    deleteMenu({menuId: data}).then(res => {
      if(res.msg === 'ok'){
        SuccessNoti('메뉴를 정상적으로 삭제하였습니다.')
        onMenuQuery();
      } else if (res === 401) {
        navigate('/');
      }
    })
  }


  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{marginBottom: 8, display: 'block'}}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined/>}
            size="small"
            style={{width: 90}}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
            Reset
          </Button>
          {/*<Button*/}
          {/*  type="link"*/}
          {/*  size="small"*/}
          {/*  onClick={() => {*/}
          {/*    confirm({ closeDropdown: false });*/}
          {/*    setSearchText(selectedKeys[0]);*/}
          {/*    setSearchedColumn(dataIndex);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Filter*/}
          {/*</Button>*/}
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('')
  };


  const MemberColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type_key',
      ...getColumnSearchProps('type'),
      sorter: {
        compare: (a, b) => {
          a = a['type'].toLowerCase();
          b = b['type'].toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      }
    },
    {
      title: 'Menu Name',
      dataIndex: 'menu_name',
      key: 'menu_name_key',
      ...getColumnSearchProps('menu_name'),
      ellipsis: true,
      sorter: {
        compare: (a, b) => {
          a = a['menu_name'].toLowerCase();
          b = b['menu_name'].toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description_key',
      ellipsis: true
    },
    {
      title: '',
      key: 'menu_action_key',
      render: (_, elm) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined/>}
            style={{borderRadius: 6}}
            onClick={() => {
              setMenuEditDataAtom(elm);
              setDrawerType("EDIT");
              setMenuDrawerVisible(true);
            }
            }/>
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => onDelete(elm.menu_id)}
            icon={<DeleteOutlined style={{color: "red"}}/>}
          >
            <Button type="danger" icon={<DeleteOutlined/>} style={{borderRadius: 6}}/>
          </Popconfirm>
        </Space>
      ),
    },
  ];


  return (
    <Table columns={MemberColumns} dataSource={menuData} pagination={{pageSize: 30}}/>
  )
}
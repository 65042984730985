import React from "react";
import {Drawer, Form, Input, Button, Checkbox, TimePicker} from 'antd';
import styled from "styled-components";
import axios from "axios";
import {ErrorNoti, SuccessNoti} from "../../../hooks/ShareNotification";
import {editStore, getStoreAddress, setStore} from "../../../services/api";
import {useNavigate} from "react-router-dom";
import {StoreEditDataAtom} from "../../../recoil";
import moment from "moment";
import {useRecoilValue} from "recoil";
// import DaumPostcodeEmbed from 'react-daum-postcode';


export const StoreDrawer = ({storeDrawerVisible, setStoreDrawerVisible, drawerType, onStoreQuery}) => {
  const [addressesData, setAddressesData] = React.useState([]);
  const [gpsData, setGpsData] = React.useState({latitude: 0, longitude: 0});
  const [storeId, setStoreId] = React.useState();
  const [monHoliDay, setMonHoliDay] = React.useState(false);
  const [tuesHoliDay, setTuesHoliDay] = React.useState(false);
  const [wedHoliDay, setWedHoliDay] = React.useState(false);
  const [thurHoliDay, setThurHoliDay] = React.useState(false);
  const [friHoliDay, setFriHoliDay] = React.useState(false);
  const [satHoliDay, setSatHoliDay] = React.useState(false);
  const [sunHoliDay, setSunHoliDay] = React.useState(false);
  const [allHoliday, setAllHoliday] = React.useState(false);
  const [weekdayHoliday, setWeekdayHoliday] = React.useState(false);
  const [weekendHoliday, setWeekendHoliday] = React.useState(false);
  const [weekendNoBreak, setWeekendNoBreak] = React.useState(false);
  const [weekAll, setWeekAll] = React.useState(true);
  const [weekdayAndWeekend, setWeekdayAndWeekend] = React.useState(false);
  const [weekIndividually, setWeekIndividually] = React.useState(false);
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const StoreEditDataValue = useRecoilValue(StoreEditDataAtom);

  React.useEffect(() => {
    if (drawerType === 'EDIT' && StoreEditDataValue) {

      const {store_id, store_name, working_hour, phone, gps_info, breakTime, address} = StoreEditDataValue;

      setStoreId(store_id)
      setGpsData({latitude: gps_info.latitude, longitude: gps_info.longitude})

      // 주말 쉬는 시간 없음 체크
      if(typeof breakTime.weekend === "string") {
        setWeekendNoBreak(true);
      }


      if (working_hour.allTime) {
        // 모든 시간 한번에 지정 버전

        if(typeof working_hour.allTime === "string" ) {
          setAllHoliday(true);
        }

        setWeekAll(true)
        setWeekdayAndWeekend(false)
        setWeekIndividually(false)

        form.setFieldsValue({
          storeName: store_name,
          address: address,
          phone: phone,
          weekdayStart: moment(breakTime.weekday.start, "A hh:mm"),
          weekdayEnd: moment(breakTime.weekday.end, "A hh:mm"),
          weekendStart: typeof breakTime.weekend === "string" ? null : moment(breakTime.weekend.start, "A hh:mm"),
          weekendEnd: typeof breakTime.weekend === "string" ? null : moment(breakTime.weekend.end, "A hh:mm"),
          allOpen: typeof working_hour.allTime === "string" ? null : moment(working_hour.allTime.open, "A hh:mm"),
          allClose: typeof working_hour.allTime === "string" ? null : moment(working_hour.allTime.close, "A hh:mm")
        })

      } else if (working_hour.weekday) {
        // 주중과 주말 따로 지정 버전

        if(typeof working_hour.weekday === "string" ) {
          setWeekdayHoliday(true);
        }

        if(typeof working_hour.weekend === "string" ) {
          setWeekendHoliday(true);
        }

        setWeekAll(false)
        setWeekdayAndWeekend(true)
        setWeekIndividually(false)

        form.setFieldsValue({
          storeName: store_name,
          address: address,
          phone: phone,
          weekdayStart: moment(breakTime.weekday.start, "A hh:mm"),
          weekdayEnd: moment(breakTime.weekday.end, "A hh:mm"),
          weekendStart: typeof breakTime.weekend === "string" ? null : moment(breakTime.weekend.start, "A hh:mm"),
          weekendEnd: typeof breakTime.weekend === "string" ? null : moment(breakTime.weekend.end, "A hh:mm"),
          weekdayOpen: typeof working_hour.weekday === "string" ? null : moment(working_hour.weekday.open, "A hh:mm"),
          weekdayClose: typeof working_hour.weekday === "string" ? null : moment(working_hour.weekday.close, "A hh:mm"),
          weekendOpen: typeof working_hour.weekend === "string" ? null : moment(working_hour.weekend.open, "A hh:mm"),
          weekendClose: typeof working_hour.weekend === "string" ? null : moment(working_hour.weekend.close, "A hh:mm"),
        })
      } else if (working_hour.mon) {
        // 7일 전부 시간 지정 버전

        if(typeof working_hour.mon === "string" ) {
          setMonHoliDay(true);
        }

        if(typeof working_hour.tues === "string" ) {
          setTuesHoliDay(true);
        }

        if(typeof working_hour.wed === "string" ) {
          setWedHoliDay(true);
        }

        if(typeof working_hour.thur === "string" ) {
          setThurHoliDay(true);
        }

        if(typeof working_hour.fri === "string" ) {
          setFriHoliDay(true);
        }

        if(typeof working_hour.sat === "string" ) {
          setSatHoliDay(true);
        }

        if(typeof working_hour.sun === "string" ) {
          setSunHoliDay(true);
        }

        setWeekAll(false)
        setWeekdayAndWeekend(false)
        setWeekIndividually(true)

        form.setFieldsValue({
          storeName: store_name,
          address: address,
          phone: phone,
          weekdayStart: moment(breakTime.weekday.start, "A hh:mm"),
          weekdayEnd: moment(breakTime.weekday.end, "A hh:mm"),
          weekendStart: typeof breakTime.weekend === "string" ? null : moment(breakTime.weekend.start, "A hh:mm"),
          weekendEnd: typeof breakTime.weekend === "string" ? null : moment(breakTime.weekend.end, "A hh:mm"),
          monOpen: typeof working_hour.mon === "string" ? null : moment(working_hour.mon.open, "A hh:mm"),
          monClose: typeof working_hour.mon === "string" ? null : moment(working_hour.mon.close, "A hh:mm"),
          tuesOpen: typeof working_hour.tues === "string" ? null : moment(working_hour.tues.open, "A hh:mm"),
          tuesClose: typeof working_hour.tues === "string" ? null : moment(working_hour.tues.close, "A hh:mm"),
          webOpen: typeof working_hour.wed === "string" ? null : moment(working_hour.wed.open, "A hh:mm"),
          webClose: typeof working_hour.wed === "string" ? null : moment(working_hour.wed.close, "A hh:mm"),
          thurOpen: typeof working_hour.thur === "string" ? null : moment(working_hour.thur.open, "A hh:mm"),
          thurClose: typeof working_hour.thur === "string" ? null : moment(working_hour.thur.close, "A hh:mm"),
          friOpen: typeof working_hour.fri === "string" ? null : moment(working_hour.fri.open, "A hh:mm"),
          friClose: typeof working_hour.fri === "string" ? null : moment(working_hour.fri.close, "A hh:mm"),
          satOpen: typeof working_hour.sat === "string" ? null : moment(working_hour.sat.open, "A hh:mm"),
          satClose: typeof working_hour.sat === "string" ? null : moment(working_hour.sat.close, "A hh:mm"),
          sunOpen: typeof working_hour.sun === "string" ? null : moment(working_hour.sun.open, "A hh:mm"),
          sunClose: typeof working_hour.sun === "string" ? null : moment(working_hour.sun.close, "A hh:mm")
        })
      }

    } else {
      form.resetFields();
    }
  }, [storeDrawerVisible])

  const onClose = () => {
    form.resetFields();

    setMonHoliDay(false);
    setTuesHoliDay(false);
    setWedHoliDay(false);
    setThurHoliDay(false);
    setFriHoliDay(false);
    setSatHoliDay(false);
    setSunHoliDay(false);
    setAllHoliday(false);
    setWeekdayHoliday(false);
    setWeekendHoliday(false);
    setWeekendNoBreak(false);

    setWeekAll(true);
    setWeekdayAndWeekend(false);
    setWeekIndividually(false);

    setAddressesData([])

    setStoreDrawerVisible(false);
  }

  const onFinish = (values) => {
    let workingHourData = {};
    const {
      storeName,
      address,
      phone,
      weekdayStart,
      weekdayEnd,
      weekendStart,
      weekendEnd,
      allOpen,
      allClose,
      weekdayOpen,
      weekdayClose,
      weekendOpen,
      weekendClose,
      monOpen,
      monClose,
      tuesOpen,
      tuesClose,
      webOpen,
      webClose,
      thurOpen,
      thurClose,
      friOpen,
      friClose,
      satOpen,
      satClose,
      sunOpen,
      sunClose
    } = values

    if (weekAll) {
      workingHourData = {
        allTime: allHoliday ? "holiday" : {open: allOpen.format("A hh:mm"), close: allClose.format("A hh:mm")}
      }
    }

    if (weekIndividually) {
      workingHourData = {
        mon: monHoliDay ? "holiday" : {open: monOpen.format("A hh:mm"), close: monClose.format("A hh:mm")},
        tues: tuesHoliDay ? "holiday" : {open: tuesOpen.format("A hh:mm"), close: tuesClose.format("A hh:mm")},
        wed: wedHoliDay ? "holiday" : {open: webOpen.format("A hh:mm"), close: webClose.format("A hh:mm")},
        thur: thurHoliDay ? "holiday" : {open: thurOpen.format("A hh:mm"), close: thurClose.format("A hh:mm")},
        fri: friHoliDay ? "holiday" : {open: friOpen.format("A hh:mm"), close: friClose.format("A hh:mm")},
        sat: satHoliDay ? "holiday" : {open: satOpen.format("A hh:mm"), close: satClose.format("A hh:mm")},
        sun: sunHoliDay ? "holiday" : {open: sunOpen.format("A hh:mm"), close: sunClose.format("A hh:mm")},
      }
    }

    if (weekdayAndWeekend) {
      workingHourData = {
        weekday: weekdayHoliday ? "holiday" : {
          open: weekdayOpen.format("A hh:mm"),
          close: weekdayClose.format("A hh:mm")
        },
        weekend: weekendHoliday ? "holiday" : {
          open: weekendOpen.format("A hh:mm"),
          close: weekendClose.format("A hh:mm")
        }
      }
    }

    let queryData = {
      storeName: storeName,
      address: address,
      phone: phone,
      workingHour: workingHourData,
      breakTime: {
        weekday: {start: weekdayStart.format("A hh:mm"), end: weekdayEnd.format("A hh:mm")},
        weekend: weekendNoBreak ? "No Break Time" : {
          start: weekendStart.format("A hh:mm"),
          end: weekendEnd.format("A hh:mm")
        }
      },
      gpsInfo: gpsData
    }

    if (drawerType === 'EDIT') {
      if (storeId > 0) {
        queryData["storeId"] = storeId;
      }
      editStore(queryData).then(res => {
        if(res?.msg === 'ok'){
          SuccessNoti('가맹점을 정상적으로 수정하였습니다.')
          onStoreQuery();
        } else if (res === 401) {
          navigate('/');
        }
      })
    } else {
      setStore(queryData).then(res => {
        if (res?.msg === 'ok') {
          SuccessNoti('가맹점를 정상적으로 저장하였습니다.')
          onStoreQuery();
        } else if (res === 401) {
          navigate('/')
        }

      })
    }
    onClose();
  };

  const getGPS = async () => {
    const address = form.getFieldValue("address")

    getStoreAddress({address: address}).then(res => {
      if (res?.msg === 'ok') {
        setAddressesData([...res.data.address]);
      } else if (res === 401) {
        navigate('/')
      }
    })
  }

  const WeekCheck = (e, date) => {
    if (e.target.checked && (date === 'mon')) {
      setMonHoliDay(true);
    } else if (!e.target.checked && (date === 'mon')) {
      setMonHoliDay(false);
    }

    if (e.target.checked && (date === 'tues')) {
      setTuesHoliDay(true);
    } else if (!e.target.checked && (date === 'tues')) {
      setTuesHoliDay(false);
    }

    if (e.target.checked && (date === 'web')) {
      setWedHoliDay(true);
    } else if (!e.target.checked && (date === 'web')) {
      setWedHoliDay(false);
    }

    if (e.target.checked && (date === 'thur')) {
      setThurHoliDay(true);
    } else if (!e.target.checked && (date === 'thur')) {
      setThurHoliDay(false);
    }

    if (e.target.checked && (date === 'fri')) {
      setFriHoliDay(true);
    } else if (!e.target.checked && (date === 'fri')) {
      setFriHoliDay(false);
    }

    if (e.target.checked && (date === 'sat')) {
      setSatHoliDay(true);
    } else if (!e.target.checked && (date === 'sat')) {
      setSatHoliDay(false);
    }

    if (e.target.checked && (date === 'sun')) {
      setSunHoliDay(true);
    } else if (!e.target.checked && (date === 'sun')) {
      setSunHoliDay(false);
    }

    if (e.target.checked && (date === 'all')) {
      setAllHoliday(true);
    } else if (!e.target.checked && (date === 'all')) {
      setAllHoliday(false);
    }

    if (e.target.checked && (date === 'weekday')) {
      setWeekdayHoliday(true);
    } else if (!e.target.checked && (date === 'weekday')) {
      setWeekdayHoliday(false);
    }

    if (e.target.checked && (date === 'weekend')) {
      setWeekendHoliday(true);
    } else if (!e.target.checked && (date === 'weekend')) {
      setWeekendHoliday(false);
    }

    if (e.target.checked && (date === 'weekendNoBreak')) {
      setWeekendNoBreak(true);
    } else if (!e.target.checked && (date === 'weekendNoBreak')) {
      setWeekendNoBreak(false);
    }
  }


  // *** 주소 검색을 위한 기능 ***
  // const handleDaumPostComplete = (data) => {
  //   let fullAddress = data.address;
  //   let extraAddress = '';
  //
  //   if (data.addressType === 'R') {
  //     if (data.bname !== '') {
  //       extraAddress += data.bname;
  //     }
  //     if (data.buildingName !== '') {
  //       extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
  //     }
  //     fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
  //   }
  //
  //   console.log("fullAddress", fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
  // };

  return (
    <Drawer
      title={drawerType === "ADD" ? "Add Store" : "Edit Store"}
      placement={'right'}
      closable={false}
      onClose={onClose}
      visible={storeDrawerVisible}
      key={"MemberDrawerKey"}
      width={"45%"}
      bodyStyle={{padding: '5%'}}
    >
      <Form onFinish={onFinish} form={form}>

        <Form.Item name={"storeName"} label="Store Name" rules={[{required: true, message: ''}]} labelCol={{span: 24}}>
          <Input/>
        </Form.Item>

        <div style={{display: "flex"}}>
          <Form.Item name={"address"} label="Address" rules={[{required: true, message: ''}]} labelCol={{span: 24}}>
            <Input onKeyPress={(e) => {
              if(e.key === 'Enter') {
                getGPS()
              }
            }}/>
          </Form.Item>

          <SearchButtonWrapper>
            <Button type={"default"} onClick={getGPS}>
              Search
            </Button>
          </SearchButtonWrapper>

          <SearchButtonWrapper>
            <Button
              type={"danger"}
              onClick={() => {
                form.setFieldsValue({
                  address: ""
                })
                setAddressesData([]);
              }}>
              Reset
            </Button>
          </SearchButtonWrapper>
        </div>

        {addressesData.length !== 0 &&
          <AddressesList>
            {addressesData.map((address, index) => {
              return (
                <AddressCard
                  key={`address-card-${index}`}
                  onClick={() => {
                    form.setFieldsValue({
                      address: address.roadAddress
                    })

                    setGpsData({latitude: Number(address.y), longitude: Number(address.x)})

                    setAddressesData([]);
                  }}>
                  {address.roadAddress}
                </AddressCard>
              )
            })}
          </AddressesList>
        }

        {/* 주소 검색을 위한 태그 */}
        {/*<DaumPostcodeEmbed onComplete={handleDaumPostComplete} autoClose={false}  />*/}

        <Form.Item name={"phone"} label="Phone" labelCol={{span: 24}}>
          <Input/>
        </Form.Item>


        {/* WORKING HOUR START */}
        <InputHeader>Working Hour</InputHeader>

        <WorkingHourCategory>
          <CategoryButton
            onClick={() => {
              setWeekAll(true)
              setWeekdayAndWeekend(false)
              setWeekIndividually(false)
            }}>
            All
          </CategoryButton>

          <CategoryButton
            onClick={() => {
              setWeekAll(false)
              setWeekdayAndWeekend(true)
              setWeekIndividually(false)
            }}>
            Weekday, Weekend
          </CategoryButton>

          <CategoryButton
            onClick={() => {
              setWeekAll(false)
              setWeekdayAndWeekend(false)
              setWeekIndividually(true)
            }}>
            Individually
          </CategoryButton>

        </WorkingHourCategory>

        {
          weekAll &&
          <div style={{display: "flex", width: "100%"}}>
            <WeekName>Mon - Sun</WeekName>

            <Form.Item name={'allOpen'} label="Open" style={{marginRight: "20px"}}>
              <TimePicker use12Hours format="A h:mm" disabled={allHoliday}/>
            </Form.Item>

            <Form.Item name={'allClose'} label="Close">
              <TimePicker use12Hours format="A h:mm" disabled={allHoliday}/>
            </Form.Item>

            <Checkbox checked={allHoliday} onChange={(e) => WeekCheck(e, "all")}
                      style={{marginTop: "5px", marginLeft: "10px", color: "#ff0200"}}>Holiday</Checkbox>
          </div>
        }

        {
          weekdayAndWeekend &&
          <>
            <div style={{display: "flex", width: "100%"}}>
              <WeekName>Mon - Fri</WeekName>

              <Form.Item name={'weekdayOpen'} label="Open" style={{marginRight: "20px"}}>
                <TimePicker use12Hours format="A h:mm" disabled={weekdayHoliday}/>
              </Form.Item>

              <Form.Item name={'weekdayClose'} label="Close">
                <TimePicker use12Hours format="A h:mm" disabled={weekdayHoliday}/>
              </Form.Item>

              <Checkbox checked={weekdayHoliday} onChange={(e) => WeekCheck(e, "weekday")}
                        style={{marginTop: "5px", marginLeft: "10px", color: "#ff0200"}}>Holiday</Checkbox>
            </div>

            <div style={{display: "flex", width: "100%"}}>
              <WeekName>Sat - Sun</WeekName>

              <Form.Item name={'weekendOpen'} label="Open" style={{marginRight: "20px"}}>
                <TimePicker use12Hours format="A h:mm" disabled={weekendHoliday}/>
              </Form.Item>

              <Form.Item name={'weekendClose'} label="Close">
                <TimePicker use12Hours format="A h:mm" disabled={weekendHoliday}/>
              </Form.Item>

              <Checkbox checked={weekendHoliday} onChange={(e) => WeekCheck(e, "weekend")}
                        style={{marginTop: "5px", marginLeft: "10px", color: "#ff0200"}}>Holiday</Checkbox>
            </div>
          </>
        }

        {
          weekIndividually &&
          <>
            <div style={{display: "flex", width: "100%"}}>
              <WeekName>Mon</WeekName>

              <Form.Item name={'monOpen'} label="Open" style={{marginRight: "20px"}}>
                <TimePicker use12Hours format="A h:mm" disabled={monHoliDay}/>
              </Form.Item>

              <Form.Item name={'monClose'} label="Close">
                <TimePicker use12Hours format="A h:mm" disabled={monHoliDay}/>
              </Form.Item>

              <Checkbox checked={monHoliDay} onChange={(e) => WeekCheck(e, "mon")}
                        style={{marginTop: "5px", marginLeft: "10px", color: "#ff0200"}}>Holiday</Checkbox>
            </div>

            <div style={{display: "flex", width: "100%"}}>
              <WeekName>Tues</WeekName>

              <Form.Item name={'tuesOpen'} label="Open" style={{marginRight: "20px"}}>
                <TimePicker use12Hours format="A h:mm" disabled={tuesHoliDay}/>
              </Form.Item>

              <Form.Item name={'tuesClose'} label="Close">
                <TimePicker use12Hours format="A h:mm" disabled={tuesHoliDay}/>
              </Form.Item>

              <Checkbox checked={tuesHoliDay} onChange={(e) => WeekCheck(e, "tues")}
                        style={{marginTop: "5px", marginLeft: "10px", color: "#ff0200"}}>Holiday</Checkbox>
            </div>

            <div style={{display: "flex", width: "100%"}}>
              <WeekName>Web</WeekName>

              <Form.Item name={'webOpen'} label="Open" style={{marginRight: "20px"}}>
                <TimePicker use12Hours format="A h:mm" disabled={wedHoliDay}/>
              </Form.Item>

              <Form.Item name={'webClose'} label="Close">
                <TimePicker use12Hours format="A h:mm" disabled={wedHoliDay}/>
              </Form.Item>

              <Checkbox checked={wedHoliDay} onChange={(e) => WeekCheck(e, "web")}
                        style={{marginTop: "5px", marginLeft: "10px", color: "#ff0200"}}>Holiday</Checkbox>
            </div>

            <div style={{display: "flex", width: "100%"}}>
              <WeekName>Thur</WeekName>

              <Form.Item name={'thurOpen'} label="Open" style={{marginRight: "20px"}}>
                <TimePicker use12Hours format="A h:mm" disabled={thurHoliDay}/>
              </Form.Item>

              <Form.Item name={'thurClose'} label="Close">
                <TimePicker use12Hours format="A h:mm" disabled={thurHoliDay}/>
              </Form.Item>

              <Checkbox checked={thurHoliDay} onChange={(e) => WeekCheck(e, "thur")}
                        style={{marginTop: "5px", marginLeft: "10px", color: "#ff0200"}}>Holiday</Checkbox>
            </div>

            <div style={{display: "flex", width: "100%"}}>
              <WeekName>Fri</WeekName>

              <Form.Item name={'friOpen'} label="Open" style={{marginRight: "20px"}}>
                <TimePicker use12Hours format="A h:mm" disabled={friHoliDay}/>
              </Form.Item>

              <Form.Item name={'friClose'} label="Close">
                <TimePicker use12Hours format="A h:mm" disabled={friHoliDay}/>
              </Form.Item>

              <Checkbox checked={friHoliDay} onChange={(e) => WeekCheck(e, "fri")}
                        style={{marginTop: "5px", marginLeft: "10px", color: "#ff0200"}}>Holiday</Checkbox>
            </div>

            <div style={{display: "flex", width: "100%"}}>
              <WeekName>Sat</WeekName>

              <Form.Item name={'satOpen'} label="Open" style={{marginRight: "20px"}}>
                <TimePicker use12Hours format="A h:mm" disabled={satHoliDay}/>
              </Form.Item>

              <Form.Item name={'satClose'} label="Close">
                <TimePicker use12Hours format="A h:mm" disabled={satHoliDay}/>
              </Form.Item>

              <Checkbox checked={satHoliDay} onChange={(e) => WeekCheck(e, "sat")}
                        style={{marginTop: "5px", marginLeft: "10px", color: "#ff0200"}}>Holiday</Checkbox>
            </div>

            <div style={{display: "flex", width: "100%"}}>
              <WeekName>Sun</WeekName>

              <Form.Item name={'sunOpen'} label="Open" style={{marginRight: "20px"}}>
                <TimePicker use12Hours format="A h:mm" disabled={sunHoliDay}/>
              </Form.Item>

              <Form.Item name={'sunClose'} label="Close">
                <TimePicker use12Hours format="A h:mm" disabled={sunHoliDay}/>
              </Form.Item>

              <Checkbox checked={sunHoliDay} onChange={(e) => WeekCheck(e, "sun")}
                        style={{marginTop: "5px", marginLeft: "10px", color: "#ff0200"}}>Holiday</Checkbox>
            </div>
          </>
        }
        {/* WORKING HOUR END */}


        {/* BREAK TIME START */}
        <InputHeader>Break Time</InputHeader>
        <div style={{display: "flex", width: "100%"}}>
          <WeekName>Weekday</WeekName>

          <Form.Item name={'weekdayStart'} label="Start" style={{marginRight: "20px"}}>
            <TimePicker use12Hours format="A h:mm"/>
          </Form.Item>

          <Form.Item name={'weekdayEnd'} label="End">
            <TimePicker use12Hours format="A h:mm"/>
          </Form.Item>
        </div>

        <div style={{display: "flex", width: "100%"}}>
          <WeekName>Weekend</WeekName>

          <Form.Item name={'weekendStart'} label="Start" style={{marginRight: "20px"}}>
            <TimePicker use12Hours format="A h:mm" disabled={weekendNoBreak}/>
          </Form.Item>

          <Form.Item name={'weekendEnd'} label="End">
            <TimePicker use12Hours format="A h:mm" disabled={weekendNoBreak}/>
          </Form.Item>

          <Checkbox checked={weekendNoBreak} onChange={(e) => WeekCheck(e, "weekendNoBreak")}
                    style={{marginTop: "5px", marginLeft: "10px", color: "#ff0200"}}>Null</Checkbox>
        </div>
        {/* BREAK TIME END */}


        <ButtonWrapper>
          <Button type={"primary"} htmlType="submit">
            {drawerType === "ADD" ? "Add" : "Edit"}
          </Button>
        </ButtonWrapper>

      </Form>

    </Drawer>
  )
}

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 5%;
`

const SearchButtonWrapper = styled.div`
  position: relative;
  top: 40px;
  padding: 0 2px;
`

const AddressesList = styled.div`
  background-color: #f1efef;
  padding: 15px;
  height: 200px;
  overflow-y: scroll;
`

const AddressCard = styled.div`
  padding: 5px 0;
  cursor: pointer;

  &:hover {
    color: blue;
  }
`

const InputHeader = styled.div`
  margin-bottom: 15px;
  margin-top: 50px;
`

const WeekName = styled.div`
  padding-top: 5px;
  margin-right: 20px;
`

const WorkingHourCategory = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: right;
`

const CategoryButton = styled.div`
  border: 1px #d7d7d7 solid;
  padding: 2px 15px;
  border-radius: 20px;
  background-color: #f1f1f1;
  color: #807e7e;
  margin-right: 10px;
  cursor: pointer;
`
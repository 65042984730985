import {notification} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined} from '@ant-design/icons';

export const SuccessNoti = (msg) => {
  notification.open({
    message: 'Success',
    description:
    msg,
    icon: <CheckCircleOutlined style={{color: 'rgb(82,213,37)'}} />,
  });
}

export const ErrorNoti = (msg) => {
  notification.open({
    message: 'ERROR',
    description:
    msg,
    icon: <CloseCircleOutlined style={{color: 'rgb(208,42,22)'}} />,
  });
}
import React from "react";
import styled from "styled-components";
import {Button} from 'antd';
import {MenuTable} from "../../blocks/DashboardBlocks/MenuTable";
import {MenuDrawer} from "../../blocks/SideDrawers/MenuDrawer";
import {useRecoilState, useSetRecoilState} from "recoil";
import {getMenu} from "../../../services/api";
import {MenuDataAtom} from "../../../recoil";
import {useNavigate} from "react-router-dom";

export const Menu = () => {
  const [menuDrawerVisible, setMenuDrawerVisible] = React.useState(false);
  const [drawerType, setDrawerType] = React.useState('')
  const [menuData, setMenuData] = useRecoilState(MenuDataAtom);
  let navigate = useNavigate();

  const onMenuQuery = () => {
    getMenu().then(res => {
      if(res.msg === 'ok'){
        setMenuData(res?.data.reverse());
      } else if (res === 401) {
        navigate('/');
      }
    })
  }

  React.useEffect(() => {
    onMenuQuery();
  }, [])

  return (
    <Component>

      <ButtonSection>
        <TotalQty>
          {/*{`Total: ${memberData.length}`}*/}
        </TotalQty>

        <Button
          type="primary"
          style={{borderRadius: 6}}
          onClick={() => {
            setDrawerType('ADD');
            setMenuDrawerVisible(true);
          }}>
          Add Menu
        </Button>
      </ButtonSection>

      <MenuTable setMenuDrawerVisible={setMenuDrawerVisible} setDrawerType={setDrawerType} menuData={menuData} onMenuQuery={onMenuQuery}/>

      <MenuDrawer menuDrawerVisible={menuDrawerVisible} setMenuDrawerVisible={setMenuDrawerVisible} drawerType={drawerType} menuData={menuData} onMenuQuery={onMenuQuery}/>

    </Component>
  )
}

const Component = styled.div`
  padding: 2%;
`

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1%;
`

const TotalQty = styled.div`
  font-size: 18px;
  font-weight: 600;
`

